//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }

        &__image {
            position: relative;
        }
    }

    .slideshow {
        @include size(100%, 600px);

        img, video {
            // position: absolute;
            object-fit: cover;
            object-position: top;
            // @include size(100%);
        }

        &__container {
            display: inherit;
            position: relative;
            @include size(100%);
        }

        &__content {
            @include flex($direction: column);
            box-sizing: border-box;
            @include absolute($left: 30px, $bottom: 60px);
            @include size(500px, auto);
            padding: 25px 30px 25px 0;
            background-color: $color-main;
            color: $color-white;

            &:before {
                @include position(absolute, $top: 0, $left: -450px);
                @include size(450px, 100%);
                content: "";
                background-color: $color-main;
            }
        }

        &__title {
            font-size: toRem(25);
            font-weight: 700;
            line-height: toRem(30);
            margin: 0 0 5px;
        }

        &__description {
            font-size: toRem(16);
            font-weight: 400;
            line-height: toRem(25);
            margin: 0 0 15px;
        }

        &--video {
            img {
                display: none;
            }
        }

        // Pagination (bullet)
        &__pagination {
            @include flex($alignItems: center);
            z-index: 2;
            width: auto;
    
            .swiper-pagination-bullet {
                @include size(10px);
                margin: 0 10px 0 0;
                background-color: $color-dark;
                border-radius: 5px;
                opacity: 1;
                transition: {
                    property: width, background-color;
                    duration: $duration;
                    timing-function: $timing;
                }
    
                &:hover, &:focus {
                    background-color: $color-white;
                }
    
                &-active {
                    background-color: $color-white;
                }
            }
        }
    
    }
}

//======================================================================================================
// Products
//======================================================================================================

.products {
    padding: 80px 0 100px;
    position: relative;
    
    &:before {
        @include position(absolute, $top: 350px, $left: 0, $bottom: 0);
        width: 100%;
        content: "";
        background-color: $color-light;
        z-index: -1;
    }

    &:after {
        @include position(absolute, $left: calc(50% + 430px), $top: 0);
        @include size(550px, 100%);
        content: "";
        background-color: $color-main;
        z-index: -2;
    }

    &__title {
        @extend %homeTitle;
    }

    &__items {
        @include flex($alignItems: flex-start, $justifyContent: space-between);
        margin: 30px 0 0;
    }

    &__item {
        width: 270px;

        &--link {
            &:hover, &:focus {
                .products {
                    &__image img {
                        transform: scale(1.05);
                    }

                    &__title--small:before {
                        width: 75px;
                    }
                }
            }
        }
    }

    &__image {
        @include size(100%, 220px);
        overflow: hidden;

        img {
            @include size(100%);
            object-fit: cover;
            transition: all $duration ease-in-out;
        }
    }

    &__title--small {
        font-size: toRem(20);
        font-weight: 700;
        line-height: toRem(25);
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin: 15px 0 10px;
        width: fit-content;
        position: relative;

        &:before {
            @include position(absolute, $left: 0, $bottom: -5px);
            @include size(0, 2px);
            content: "";
            background-color: $color-main;
            z-index: 0;
            transition: all $duration ease-in-out;
        }
    }

    &__description {
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(25);
    }
}

//======================================================================================================
// Documents
//======================================================================================================

.front-page__section {
    @include flex($justifyContent: space-between);
    margin: 100px auto 160px;
    position: relative;

    &:before {
        @include position(absolute, $bottom: -60px, $left: calc(50% - 1005px));
        @include size(650px, 340px);
        content: "";
        background-color: $color-main;
        z-index: -1;
    }
}

.home-documents {
    &__title {
        @extend %homeTitle;
    }

    &__item {
        @include flex($alignItems: center);
        gap: 30px;
        margin: 30px 0 0;
    }

    &__image {
        @include size(295px, 400px);
        box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.1);

        img {
            @include size(100%);
            object-fit: cover;
        }
    }

    &__buttons {
        @include flex($direction: column);
        gap: 15px;
    }

    &__button {
        @include size(40px);
        background-color: $color-main;
        border-radius: 50%;
        transition: all $duration ease-in-out;
        
        svg {
            fill: $color-white;
            @include size(100%);
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            background-color: $color-dark;

            svg {
                fill: $color-white;
            }
        }
    }
}

//======================================================================================================
// Social Wall
//======================================================================================================

.social-wall {
    position: relative;
    z-index: 1;

    &__title {
        @extend %homeTitle;
    }

    &__wrapper {
        width: 630px;
        margin: 30px 0 0;
    }

    #ff-stream-1 {
        padding: 0;
        max-height: 400px!important;
        min-height: 400px!important;
        height: 400px!important;
        background: transparent!important;

        .ff-header, .ff-nickname, .ff-timestamp, .ff-loadmore-wrapper, .ff-item-meta {
            display: none;
        }

        .picture-item__inner {
            background: transparent!important;
            box-shadow: 0 0 0 0 rgba(0,0,0,0)!important;

            .ff-item-cont {
                height: 400px;
            }
        }

        .ff-upic-round .ff-img-holder:first-child {
            border-radius: 0 !important;
        }

        .ff-stream-wrapper {
            display: flex;
            margin: 0;
            padding: 0;
            height: 400px!important;

            .ff-item {
                width: 300px!important;
                transition: box-shadow $duration ease-in-out!important;
                
                &:nth-child(1) {
                    transform: unset!important;
                }

                &:nth-child(2) {
                    transform: translateX(330px)!important;
                }

                &:hover {
                    .ff-img-holder img {
                        height: 108%;
                        min-width: 108%;
                    }

                    .ff-content:before {
                        width: 60px;
                    }
                }
            }
        }

        .ff-img-holder {
            @include size(100%!important, 200px!important);
            overflow: hidden;
            position: relative;

            &:before, &:after {
                @include position(absolute, $top: 15px, $left: 15px);
                content: "";
                @include size(40px);
            }

            &:before {
                mask-image: url(/wp-content/themes/machines-simon/assets/src/images/sprite-images/Pictos/ic_facebook.svg);
                mask-size: 40px;
                background-color: $color-white;
                z-index: 1;
            }

            &:after {
                background: $color-main;
                border-radius: 50px;
            }

            img {
                @include size(100%);
                object-fit: cover;
                transition: all $duration ease-in-out;
            }
        }

        .ff-content {
            background-color: $color-light;
            height: 200px!important;
            padding: 20px 20px 75px;
            margin: 0;
            font-size: toRem(14);
            font-weight: 400;
            line-height: toRem(20);
            position: relative;

            &:before {
                @include position(absolute, $bottom: 60px, $left: 20px);
                @include size(50px, 1px);
                content: "";
                background-color: $color-dark;
                transition: all $duration ease-in-out;
            }
        }

        .ff-item-bar {
            @include position(absolute, $top: 345px);
            border: 0;
            width: 100%;
            padding: 15px 20px;
            z-index: 2;
            background-color: $color-light;
        }
    }
}

//======================================================================================================
// Actualités
//======================================================================================================

.news {
    margin: 0 0 100px;

    &__top {
        @include flex($alignItems: center, $justifyContent: space-between);
    }

    &__title {
        @extend %homeTitle;
    }

    &__button {
        width: 230px;
        margin: 0;
    }

    &__list {
        @include flex($justifyContent: space-between);
        margin: 30px 0 0;
    }

    &__link {
        width: 570px;
        @include flex($direction: column);
        gap: 15px;

        &:hover, &:focus {
            .news {
                &__image img {
                    transform: scale(1.05);
                }
                
                &__content:before {
                    width: 75px;
                }
            }
        }
    }

    &__image {
        overflow: hidden;
        @include size(100%, 270px);

        img {
            object-fit: cover;
            @include size(100%);
            transition: all $duration ease-in-out;
        }

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-gray;
            background-position: center;
        }
    }

    &__content {
        @include flex($direction: column);
        gap: 5px;
        position: relative;

        &:before {
            @include position(absolute, $left: 0, $bottom: -7px);
            @include size(0, 2px);
            content: "";
            background-color: $color-main;
            z-index: 0;
            transition: all $duration ease-in-out;
        }
    }

    &__title--small {
        font-size: toRem(25);
        font-weight: 700;
        line-height: toRem(30);
        margin: 0;
        padding: 0;
        color: $color-dark;
        width: fit-content;
    }

    &__introduction {
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(25);
        color: $color-dark;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home-slideshow {
    
        .slideshow {
            height: 480px;
    
            &__content {
                width: 460px;
            }
    
            &__title {
                margin: 0 0 10px;
            }
    
            &__description {
                font-size: toRem(14);
            }
        }
    }

    .products {
        padding: 80px 0;
        
        &:before {
            top: 290px;
        }

        &:after {
            left: calc(50% + 280px);
        }
    
        &__item {
            width: 220px;
        }
    
        &__image {
            height: 190px;
        }
    
        &__title--small {
            font-size: toRem(18);
        }
    
        &__description {
            font-size: toRem(14);
            line-height: toRem(20);
        }
    }

    .front-page__section {
        margin: 80px auto 140px;
    
        &:before {
            bottom: 0;
            height: 275px;
            left: calc(50% - 910px);
        }
    }
    
    .home-documents {
        &__item {
            gap: 20px;
        }
    
        &__image {
            @include size(270px, 365px);
        }

        &__buttons {
            margin: 35px 0 0;
        }
    }

    .social-wall {
        &__wrapper {
            width: 515px;
        }
    
        #ff-stream-1 {
            .ff-stream-wrapper {
                .ff-item {
                    width: 247px!important;
    
                    &:nth-child(2) {
                        transform: translateX(268px) !important;
                    }
                }
            }
        }
    }

    .news {
        margin: 0 0 80px;
    
        &__link {
            width: 455px;
        }
    
        &__image {
            height: 250px;
        }
    
        &__introduction {
            font-size: toRem(14);
            line-height: toRem(20);
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .home-slideshow {
        position: relative;

        .slideshow {
            min-height: 450px;
            height: auto;

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                    max-height: 320px;
                }
            }

            img {
                max-height: 320px;
                min-height: 320px;
            }

            &__content {
                width: 62%;
                left: 20px;
                bottom: unset;
                top: 240px;
            }

            &__title {
                font-size: toRem(20);
            }
        }
    }

    .products {
        padding: 40px 0 80px;

        &:before {
            top: 245px;
        }

        &:after {
            top: -140px;
            left: unset;
            right: 0;
            width: 115px;
        }

        &__items {
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 20px;
        }
    
        &__item {
            width: calc(50% - 10px);
        }
    }

    .front-page__section {
        margin: 80px auto;
        flex-wrap: wrap;
        gap: 80px 20px;
    
        &:before {
            bottom: unset;
            left: -40px;
            width: 260px;
            top: 190px;
        }
    }

    .social-wall {
        &__wrapper {
            width: 320px;
        }
    
        #ff-stream-1 .ff-stream-wrapper .ff-item {
            width: 320px!important;

            &:nth-child(2) {
                display: none;
            }
        }
    }

    .news {
        &__link {
            width: calc(50% - 15px);
        }
    
        &__image {
            height: 180px;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .home-slideshow {
        .container--content {
            left: 0;
            right: 0;
        }
        .slideshow {
            min-height: 335px;

            img {
                max-height: 200px;
                min-height: 200px;
            }

            &__content {
                width: calc(100% - 60px);
                top: 165px;
                padding: 20px;
                left: 50%;
                transform: translateX(-50%);

                &:before {
                    display: none;
                }
            }

            &__title {
                font-size: toRem(16);
                line-height: toRem(25);
                margin: 0 0 5px;
            }
        }
    }

    .products {
        padding: 55px 0 60px;

        &:before {
            top: 240px;
        }

        &:after {
            display: none;
        }

        &__items {
            margin: 20px 0 0;
        }
    
        &__item {
            width: 100%;
        }

        &__image {
            height: 200px;
        }
    }
    
    .front-page__section {
        gap: 120px 20px;
    
        &:before {
            @include size(380px, 270px);
            left: calc(50% + -320px);
            top: 140px;
        }
    }

    .home-documents {
        margin: auto;

        &__item {
            margin: 20px 0 0;
        }

        &__image {
            @include size(240px, 325px);
        }

        &__buttons {
            margin: 0;
        }
    }

    .social-wall {
        width: 100%;
        &__wrapper {
            width: 100%;
            margin: 20px 0 0;
        }
    
        #ff-stream-1 .ff-stream-wrapper .ff-item {
            width: 100%!important;
        }
    }

    .news {
        margin: 0 0 150px;

        &__container {
            position: relative;
        }

        &__list {
            flex-direction: column;
            gap: 30px;
            margin: 20px 0 0;
        }
        
        &__link {
            width: 100%;
        }

        &__button {
            @include position(absolute, $bottom: -70px);
        }
    }
}
