@keyframes headerFixed {
    from {
        top: -200px;
    }
    to {
        top: 0;
    }
}

.header {
    position: relative;
    z-index: 10;
    min-height: 220px;

    &__container {
        background-color: $color-white;
    }

    &__title {
        font-size: 0;
        line-height: 0;
        @include size(0);
        display: flex;
    }

    &__content {
        @include flex($alignItems: flex-end, $justifyContent: space-between);
    }

    &__link {
        margin: 0 0 30px;
    }

    &__right {
        @include flex($alignItems: flex-end, $direction: column);
        padding: 40px 0 10px;
    }

    &__buttons {
        @include flex($alignItems: center);
        gap: 30px;
    }

    &__button {
        &--entreprise {
            font-size: toRem(16);
            font-weight: 700;
            line-height: toRem(20);
            letter-spacing: 0.05em;
            text-transform: uppercase;
            padding: 0 15px 0 0;
            position: relative;
            transition: all $duration ease-in-out;
            z-index: 1;

            span {
                @include position(absolute, $top: 2.5px, $right: 0);
                @include size(5px, 15px);
                background-color: $color-main;
                z-index: -1;
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus {
                color: $color-white;
                
                span {
                    width: 205px;
                }
            }
        }

        &--group {
            @include flex($alignItems: center);
            gap: 10px;
            padding: 10px 20px;
            font-size: toRem(16);
            font-weight: 600;
            line-height: toRem(20);
            letter-spacing: 0.05em;
            text-transform: uppercase;
            background-color: $color-light;
            transition: all $duration ease-in-out;

            svg {
                @include size(15px);
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: $color-main;
                color: $color-white;
                
                svg {
                    fill: $color-white;
                }
            }
        }
    }

    &--fixed {
        .header {
            &__container {
                @include position(fixed, $top: 0);
                @include size(100%, 110px);
                animation: 1s headerFixed;
                box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.1);
            }

            &__buttons {
                display: none;
            }

            &__content {
                height: 100%;
                align-items: center;
            }

            &__link {
                margin: 5px 0 0;
            }

            &__right {
                padding: 20px 0 10px;
            }
        }

        .tools {
            display: none;
        }

        .nav-main .submenu {
            top: 110px;
            box-shadow: 1px 10px 10px 0 rgba(0,0,0,.04);
        }
    }
}

//======================================================================================================
// Tools
//======================================================================================================
.tools {
    background-color: $color-main;

    &__container {
        @include flex($alignItems: center, $justifyContent: flex-end);
        gap: 50px;
        height: 50px;
    }

    &__item {
        @include flex($alignItems: center);
        gap: 10px;
        margin: 0;
        padding: 0;
        position: relative;

        &:before {
            @include position(absolute, $left: 30px, $bottom: -5px);
            @include size(0, 2px);
            content: "";
            background-color: $color-white;
            z-index: 0;
            transition: all $duration ease-in-out;
        }

        svg {
            @include size(20px);
            fill: $color-white;
        }

        span {
            font-size: toRem(16);
            font-weight: 600;
            line-height: toRem(20);
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: $color-white;
        }

        &--search--disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &:hover, &:focus {
            background-color: $color-main;
            border-color: $color-main;
            color: $color-white;

            &:before {
                width: calc(100% - 30px);
            }

            svg {
                fill: $color-white;
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        &__link {
            margin: 0 0 25px;
            @include size(190px, 50px);

            svg {
                @include size(100%);
            }
        }
    
        &__buttons {
            gap: 42px;
        }
    
        &__button {
            &--entreprise {
                letter-spacing: 0;
                padding: 0 15px 0 0;
            }
        }
    
        &--fixed {
            .header {
                &__container {
                    height: 90px;
                }

                &__link {
                    margin: 0;
                }

                &__right {
                    padding: 15px 0 10px;
                }
            }

            .nav-main .submenu {
                top: 90px;
            }
        }
    }

    .tools {
        &__container {
            gap: 37px;
        }
    
        &__item span {
            font-size: toRem(14);
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .header {
        min-height: 170px;

        &__link {
            margin: 0 0 5px;
        }

        &__right {
            flex-direction: row-reverse;
            gap: 17px;
        }

        &__button--entreprise {
            display: none;
        }
    }

    .tools {
        &__container {
            gap: 38px;
        }
        
        &__item {
            span {
                display: none;
            }

            &:before {
                display: none;
            }
        }

        &__translate {
            z-index: 8;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .header {
        &__content {
            flex-direction: column;
            align-items: center;
            margin: 30px auto;
            gap: 20px;
        }

        &__link {
            margin: 0;
        }

        &__right {
            padding: 0;
            gap: 24px;
        }

        &__button--group {
            padding: 10px;
        }
    }

    .tools {
        &__container {
            justify-content: center;
        }
    }
}
