@use "sass:list"; // https://sass-lang.com/documentation/breaking-changes/slash-div

.wrapper_map {
    position: relative;
    width: 100%;
    height: 600px;
}

.maps-container {
    display: flex;
    width: 100%;

    aside {
        position: relative;
    }

    .filter {
        &--side {
            width: 340px;
            height: 100%;
        }

        &__container {
            max-height: 610px;
            overflow-y: auto;
            padding-bottom: 0;
            padding-top: 0;
        }
    }

    #maps_publications {
        flex: 1 0 0;

        .wrapper_map {
            position: relative;
            width: 100%;
            height: 700px;
        }
    }

    .maps-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 90px;

        button {
            margin: 0;
        }
    }
}

path.leaflet-interactive {
    stroke-width: 4;
}

.leaflet-container {
    &#map {
        a.leaflet-popup-close-button {
            width: 38px;
            height: 26px;
            font: list.slash(1.625rem, $line-height) Tahoma, Verdana, sans-serif;
            color: $color-gray;
            font-weight: $font-weight-light;

            &:hover,
            &:focus {
                color: $color-main;
            }
        }
    }
}

.wrapper_map .leaflet-container {
    .leaflet-control-container .leaflet-control-geosearch form .reset {
        width: 40px;
        height: 40px;
        margin: 0;
        padding: 10px;
        background-color: $btn-empty-bg;
        border: $btn-border-width $btn-border-style $btn-empty-border-color;
        border-radius: 0;
        @include font-size(24);
        color: $btn-empty-color;
    
        &:hover, &:focus {
            background-color: $btn-empty-bg--hover;
            border-color: $btn-empty-border-color--hover;
            color: $btn-empty-color--hover;
        }
    }
}

.creasit-marker {
    background-image: url($urlShortImage + 'custom-marker.png') !important;
}


// Marker popup initial
.leaflet-popup-content-wrapper {
    border-radius: $border-radius !important;

    .leaflet-popup-content {
        margin: 0;

        .geo-search {
            padding: 20px;
            font-size: $font-size--text;
            font-family: $font-family;
            color: $color-text;
        }

        .marker-popup {
            padding: 20px;

            p {
                line-height: $line-height;
            }

            p:empty {
                &::before {
                    display: none;
                }
            }

            &__title {
                margin-top: 10px;
                margin-bottom: 0;
                padding-bottom: 5px;
                border: 0;
                font-size: $font-size--4;
                color: $color-dark;
            }

            &__category {
                margin-top: 0;
                font-family: $font-family;
                font-size: $font-size--text-xsmall;
                text-transform: uppercase;
            }
        
            &__location {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 10px 0 10px 70px;
                border-top: 1px solid $color-gray;
                min-height: 60px;
        
                p {
                    margin: 0;
                    font-weight: $font-weight--heading;
                    font-size: $font-size--text;
                    font-family: $font-family;
                    color: $color-text;
                }
        
                svg {
                    position: absolute;
                    left: 20px;
                    fill: $color-svg--maps;
                }
            }
        
            &__content {
                display: flex;
                border-top: 1px solid $color-gray;
                padding: 15px 0 0;
        
                img {
                    margin-right: 15px;
                    height: 80px;
                }
            }
        
            &__description {
                p {
                    margin: 0 0 10px;
                    font-size: $font-size--text;
                    font-family: $font-family;
                    color: $color-text;
                }
        
                a {
                    color: $btn-color;
        
                    &:hover,
                    &:focus {
                        color: $btn-color--hover;
                    }
                }
            }

            &__itinerary {
                &::after {
                    content: '' !important;
                    @extend %external_linkAfter;
                    @include relative($bottom: -1px, $top: 6px !important);
                    float: right;
                    margin-left: 2px;
                    background-color: $color--card;
                }
            }
        }
    }
}


// Close button popup
.wrapper_map .leaflet-container {
    a.leaflet-popup-close-button {
        @include absolute($top: 0, $right: 0);
        @include flex($alignItems: center, $justifyContent: center);
        @include size(40px);
        padding: 0;
        font: toRem(28) Tahoma,Verdana,sans-serif;
        line-height: 1;
        color: $color-gray;

        &:hover, &:focus {
            color: $color-text;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .maps-container {
        flex-direction: column;
        margin-bottom: 20px;

        .filter {
            &--side {
                width: 100%;
                height: 100%;
                max-height: inherit;
                overflow-y: hidden;
                padding-bottom: 60px;
            }

            &__container {
                display: flex;
                flex-wrap: wrap;
                gap: 0 20px;
                max-height: inherit;
                margin-bottom: 25px;

                > div {
                    width: calc(100% / 2 - (20px / 2));

                    &:first-of-type {
                        width: 100%;
                    }
                }
            }
        }
        
        
    }

}

// 640
@media screen and (max-width: $small) {

    .maps-container #maps_publications .wrapper_map {
        height: 550px;
    }

    .wrapper_map .leaflet-container .leaflet-control-container .leaflet-control-geosearch form {
        width: 300px;
    }

    .maps-container .filter__container>div {
        width: 100%;
    }

}
