.footer {
    background-color: $color-light;
    padding: 80px 0 0;

    &__container {
        display: flex;
    }

    &__left {
        display: flex;
    }

    &__coordonnees {
        width: 220px;
        margin-right: 170px;
    }

    &__title {
        font-size: toRem(22);
        font-weight: 700;
        line-height: toRem(25);
    }

    &__text {
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(25);
        margin: 20px 0 20px;
    }

    &__phone {
        text-decoration: underline;
        text-decoration-color: transparent;
        color: $color-text;
        transition: all $duration ease-in-out;

        &:hover, &:focus {
            color: $color-text;
            text-decoration-color: $color-dark;
        }
    }

    &__buttons {
        @include flex($direction: column);
        gap: 25px;
        width: 200px;
        margin-right: 100px;
    }

    &__button {
        @include flex($alignItems: center, $justifyContent: center);
        gap: 10px;
        @include size(100%, 40px);
        background-color: transparent;
        border: 1px solid $color-dark;
        color: $color-dark;
        text-transform: uppercase;
        font-size: toRem(15);
        font-weight: 600;
        line-height: toRem(20);
        letter-spacing: 0.05em;
        transition: all $duration ease-in-out;

        svg {
            @include size(20px);
            fill: $color-dark;
            transition: all $duration ease-in-out;
        }

        &--contact {
            background-color: $color-main;
            border-color: $color-main;
            color: $color-white;

            svg {
                fill: $color-white;
            }
        }

        &:hover, &:focus {
            background-color: $color-dark;
            border-color: $color-dark;
            color: $color-white;

            svg {
                fill: $color-white;
            }
        }
    }

    &__right {
        @include flex($alignItems: center);
        gap: 40px;
    }

    &__encart {
        @include size(220px, 170px);
        @include flex($direction: column);
        gap: 10px;
        background-color: $color-main;
        border-color: $color-main;
        color: $color-white;
        padding: 30px 20px;
        transition: all $duration ease-in-out;

        p:first-child {
            font-size: toRem(16);
            font-weight: 700;
            line-height: toRem(20);
            letter-spacing: 0.05em;
            text-transform: uppercase;
        }

        p:last-child {
            font-size: toRem(14);
            font-weight: 400;
            line-height: toRem(20);
        }

        &:hover, &:focus {
            background-color: $color-dark;
            color: $color-white;
        }
    }

    &__menu {
        padding: 80px 0 60px;

        &__list {
            @include flex($alignItems: center, $justifyContent: space-between);
            width: 100%;
        }

        &__link {
            font-size: toRem(14);
            font-weight: 400;
            line-height: toRem(20);
            text-decoration: underline;
            text-decoration-color: transparent;
            color: $color-text;
            transition: all $duration ease-in-out;

            &:hover, &:focus {
                color: $color-text;
                text-decoration-color: $color-dark;
            }
        } 
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer {
        &__coordonnees {
            margin-right: 40px;
        }
    
        &__text {
            margin: 15px 0 30px;
        }
    
        &__buttons {
            width: 180px;
            margin-right: 40px;
        }
    
        &__right {
            gap: 20px;
        }

        &__menu {
            padding: 75px 0 60px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .footer {
        padding: 100px 0 0;

        &__container {
            flex-wrap: wrap;
            gap: 30px; 
        }

        &__coordonnees {
            margin-right: 50px;
        }

        &__text {
            margin: 5px 0 25px;
        }

        &__buttons {
            gap: 20px;
        }
        
        &__right {
            width: 100%;
        }

        &__encart {
            @include size(calc(50% - 10px), 150px);
        }

        &__menu {
            padding: 55px 0 80px;

            &__list {
                flex-wrap: wrap;
                gap: 5px 45px;
                justify-content: flex-start;
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .footer {
        padding: 80px 0 0;

        &__left {
            flex-wrap: wrap;
            gap: 20px 50px;
        }

        &__coordonnees {
            margin-right: 0;
        }

        &__text {
            margin: 10px 0 25px;
        }

        &__buttons {
            gap: 15px;
            margin: 10px 0 0;
        }

        &__right {
            flex-direction: column;
            gap: 15px;
        }

        &__encart {
            @include size(100%, fit-content);

            p:first-child br {
                display: none;
            }
        }
    }
}
