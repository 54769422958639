.cover {
    position: relative;

    &__image-wrapper {
        width: 100%;
        height: 600px;
        background-color: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background-color: $color-white;
        padding: 60px 0;
    }

    &__title {
        font-size: toRem(35);
        font-weight: 800;
        line-height: toRem(40);
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin: 0 0 15px;
    }

    &__lead-wrapper {
        p {
            font-size: toRem(18);
            font-weight: 600;
            line-height: toRem(25);
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            height: 480px;
        }

        &__informations {
            padding: 50px 0;
        }

        &__title {
            font-size: toRem(30);
            line-height: toRem(35);
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 400px;
        }

        &__informations {
            padding: 40px 0;
        }

        &__title {
            font-size: toRem(25);
            line-height: toRem(30);
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__image-wrapper {
            height: 200px;
        }
    }
}
