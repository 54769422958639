.nav-main {
    z-index: 10;

    // Container menu
    &__container {
        @include flex($justifyContent: space-between, $alignItems: center);
        padding: 0;
    }

    // Main menu
    .menu {
        @include flex($alignItems: center);
        gap: 70px;

        &__item {
            &.menu-item-has-children.js-open-submenu {
                .submenu {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: inherit;
                }

                .menu__link {
                    color: $color-text;
                }
            }

            &--entreprise {
                display: none;
            }
        }   
        
        &__link {
            position: relative;
            @include flex($alignItems: center);
            height: 80px;
            padding: 0;
            color: $color-dark;
            font-size: toRem(18);
            font-weight: 700;
            line-height: toRem(20);
            letter-spacing: 0.05em;
            text-transform: uppercase;
            transition: {
                property: background-color, color;
                duration: $duration;
                timing-function: $timing;
            }
            
            &:before {
                @include position(absolute, $left: 0, $bottom: 21px);
                @include size(0, 2px);
                content: "";
                background-color: $color-main;
                z-index: 0;
                transition: all $duration ease-in-out;
            }
    
            &:hover, &:focus {
                &:before {
                    width: 100%;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        @include flex($justifyContent: center);
        @include position(absolute, $top: 220px, $right: 0);
        z-index: 1;
        width: 100%;
        padding: 40px 15px 60px;
        background-color: $color-white;
        border-top: 2px solid $color-main;
        box-shadow: $shadow;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: {
            property: visibility, opacity, pointer-events;
            duration: $duration;
            timing-function: $timing;
        }

        &__nav {
            width: 100%;
            column-count: 3;
            column-gap: 30px;
        }

        &__item {
            display: inline-flex;
            width: 100%;
            margin: -1px; // Fix space beetween inline-flex
            padding: 0;
            line-height: initial;
            position: relative;

            &::before {
                content: "";
                display: inherit;
                position: absolute;
                bottom: 0;
                width: 0;
                height: 2px;
                background-color: $color-text;
                transition: width $duration $timing;
            }

            &:hover, &:focus {
                &::before {
                    width: 100%;
                }

                .submenu__link {
                    padding: 12px 30px 12px 15px;

                    &::after {
                        color: $color-text;
                    }
                }
            }
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 1px; // Fix space beetween inline-flex
            padding: 12px 30px 12px 0;
            border-bottom: 1px solid rgba($color-dark, 0.5);
            color: $color-text;
            font-size: toRem(16);
            font-weight: 700;
            line-height: toRem(25);
            transition: all $duration ease-in-out;

            &::after {
                content: '\f345';
                font: normal 20px/1 dashicons;
                position: absolute;
                right: 0;
                color: rgba($color-dark, 0.5);
                transition: color $duration $timing;
            }

            &--grandchild {
                display: flex;
                padding: 17px 10px 17px 15px;
                border-bottom: 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;

                &::before {
                    display: none;
                }
            }
        }
        
        &__close {
            @include absolute($top: 13px);
            @extend %SpaceFull-to-Container--right;
            margin: 0;
            padding: 0;
            background-color: $color-bg--transparent;

            &__icon {
                display: block;
                @include size(26px);
                background-color: $color-main;
                border: $btn-border-width $btn-border-style $btn-border-color;
                border-radius: $border-radius--round;
                fill: $color-white;
            }

            &__mobile {
                display: none;
            }

            svg {
                transition: {
                    property: background-color, fill;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                .submenu__close__icon {
                    background-color: $color-bg--transparent;
                    fill: $color-dark;
                }
            }
        }
    }
    
    // For responsive
    &__button, &__icon, &__close {
        display: none;
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main {
        .menu {
            gap: 50px;

            &__link {
                font-size: toRem(16);
                letter-spacing: 0;
                height: 60px;

                &:before {
                    bottom: 12px;
                }
            }
        }

        .submenu {
            padding: 40px 15px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    // if menu open (no scroll on body)
    body.js-open-menu {
        overflow: hidden;
    }    

    .nav-main {
        position: relative;

        // If menu open
        &.js-open-menu {
            
            // Container menu
            .nav-main__container {
                left: 0;
            }
        }

        // Container menu
        &__container {
            position: fixed;
            top: 0;
            left: -100vw;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 15px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-white;
            padding: 40px 0;
            transition: left $duration $timing;
        }
        
        // Burger menu responsive - Open menu
        &__button {
            @include flex($alignItems: center, $justifyContent: center);
            @include size(118px, 40px);
            position: relative;
            column-gap: 10px;
            margin: 0;
            padding: 0;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            font-size: toRem(16);
            font-weight: 700;
            line-height: toRem(16);
            color: $color-dark;
            border: 1px solid $color-main;
            text-transform: uppercase;
            cursor: pointer;

            &:hover, &:focus {
                background-color: $color-main;
                border-color: $color-main;
                color: $color-white;
            }
        }

        // Button icon (Burger menu responsive - Open menu)
        &__icon {
            position: relative;
            top: 0;
            display: block;
            width: 17px;
            height: 2px;
            background-color: $color-dark;

            &:before, 
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background-color: $color-dark;
            }

            &:before {
                top: 6px;
            }

            &:after {
                top: -6px;
            } 
        }

        // Burger menu responsive - Close menu
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            min-height: 40px;
            margin: 0 40px 0 auto;
            padding: 10px;
            background-color: transparent;
            border: $btn-border-width $btn-border-style $color-main;
            border-radius: $border-radius--round;
            transition: all $duration ease-in-out;

            svg {
                width: 12px;
                height: 12px;
                margin: 0;
                fill: $color-dark;
            }

            &:hover,
            &:focus {
                background-color: $color-main;
                border-color: $color-main;
                border-color: $color-main;

                svg {
                    fill: $color-white;
                }
            }
        }

        // Main menu
        .menu {
            flex-direction: column;
            flex-wrap: nowrap;
            overflow-y: auto;
            width: 100%;
            padding: 0 40px;
            gap: 0;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%!important;

                &--entreprise {
                    display: flex;
                }
    
                &.menu-item-has-children {

                    // If sub menu open
                    &.js-open-submenu {   
                        .submenu {
                            right: 0;
                        }
    
                        .menu__link {
                            background-color: $color-bg--transparent;
                            color: $color-dark;
                        }
                    }
                }
    
                &:hover, &:focus {   
                    .menu__link {
                        background-color: $color-bg--transparent;
                        color: $color-dark;
                    }
                }
            }

            &__link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: fit-content;
                height: auto;
                padding: 20px 0;
                color: $color-dark;
                transition: none;

                &--entreprise {
                    padding: 0 15px 0 0;
                    margin: 20px 0;
                    z-index: 0;

                    &:before {
                        display: none;
                    }

                    &:hover, &:focus {
                        span {
                            width: 5px;
                        }
                    }
                }

                &:before {
                    width: 100%;
                }

                &::after {
                    display: none;
                }

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                    color: $color-white;
                }
            }
        }

        // Sub menu
        .submenu {
            left: inherit;
            top: 0;
            right: 100%;
            z-index: 1;
            @include flex($direction: column, $justifyContent: flex-start);
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 40px 0 60px 0;
            background-color: $color-white;
            transition: {
                property: visibility, pointer-events, right;
                duration: $duration;
                timing-function: $timing;
            }
            opacity: 1;
            border-width: 0px;

            // Burger menu responsive - Close submenu
            &__close {
                position: static;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 40px;
                height: 40px;
                margin: 0 0 0 40px;
                padding: 0;
                background-color: transparent;
                border: $btn-border-width $btn-border-style $color-main;
                border-radius: $border-radius--round;
                order: 1;
                transition: all $duration ease-in-out;

                &:hover,
                &:focus {
                    background-color: $color-main;
                    border-color: $color-main;

                    svg {
                        fill: none;
                        stroke: $color-white;
                    }
                }

                &__mobile {
                    display: block;
                    width: 40px;
                    height: 40px;
                    fill: none;
                    stroke: $color-dark;
                    stroke-width: 2px;
                }

                &__icon, span {
                    display: none;
                }
            }

            &__nav {
                display: flex;
                flex-direction: column;
                max-width: 100%;
                padding: 0 40px;
                overflow-y: auto;
                order: 2;
            }

            &__item {
                margin: auto; 
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main {
            &__container, .submenu {
                padding-top: 70px;
            }
        }
    }


}


// 640
@media screen and (max-width: $small) {
    .nav-main {
        > .container {
            margin: 0;
            padding: 0;
            max-width: unset;
        }

        &__button {
            width: 108px;
            column-gap: 15px;
        }

        &__close {
            margin-top: 40px;
        }
    }
}