.bloc-content--404 {
    margin: 80px 0 40px;

    h2 {
        margin: 0 0 50px;
        border: 0;
        font-size: 8.5rem;
        display: flex;
        justify-content: center;
        text-align: center;
        color: $color-dark;

        &:before, &:after {
            display: none!important;
        }
    }

    p {
        font-size: $font-size--5;
        text-align: center;
    }
}

.search--404 {

    .search-container::before {
        display: none;
    }

    .searchInput {
        z-index: 1;
    }

    .search-svg {
        z-index: 2;
    }
}

.grid-center-noGutter.container__buttons {
    margin: 30px 0;
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .search--404 {
        width: 100%;
    }
}

// 640
@media screen and (max-width: $small) {

    .bloc-content--404 {
        h2 {
            font-size: 7rem;
        }
    }
}